<template>
  <div class="mb-4 flex justify-end items-center">
    <asom-button
      variant="primary"
      outline
      rounded="none"
      :icon="buttonIcon"
      @click="toggleModal(true)"
    >
    </asom-button>
    <asom-modal
      v-model="showModal"
      title="Move Cash Bag"
      size="md"
      :dismissible="false"
    >
      <asom-alert v-if="error" variant="error" :errorMessage="error" />
      <div>
        <asom-form-field
          label="Cash Bag Serial Number"
          required
          :state="inputStates('cashBagData.cashBagSerialNumber')"
        >
          <scan-code
            v-model="cashBagData.cashBagSerialNumber"
            :state="inputStates('cashBagData.cashBagSerialNumber')"
          />
        </asom-form-field>
        <asom-form-field
          label="Remarks"
          :state="inputStates('cashBagData.remarks')"
        >
          <asom-input-textarea
            v-model="cashBagData.remarks"
            :state="inputStates('cashBagData.remarks')"
          />
        </asom-form-field>
        <div class="flex flex-row-reverse pt-4 gap-4">
          <asom-button @click="onSubmit" text="Add" />
          <asom-button
            @click="toggleModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>
<script>
import { nanoid } from "nanoid";
import ScanCode from "../../_ScanCode.vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";

export default {
  components: {
    ScanCode,
  },
  mixins: [inputStates],
  props: ["buttonIcon"],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  emit: ["add-bag"],
  data() {
    return {
      cashBagData: {
        cashBagSerialNumber: "",
        remarks: "",
      },
      error: null,
      showModal: false,
    };
  },
  validations() {
    return {
      cashBagData: {
        cashBagSerialNumber: {
          required,
        },
      },
    };
  },
  methods: {
    toggleModal(value) {
      this.showModal = value;
      if (value) {
        this.cashBagData.cashBagSerialNumber = "";
        this.cashBagData.remarks = "";
        this.error = null;
        this.v$.$reset();
      }
    },
    onSubmit() {
      this.error = null;
      this.v$.$touch();
      if (this.v$.cashBagData.$invalid) {
        this.error = "Please complete all required fields";
      } else {
        this.$emit("add-bag", {
          tempId: nanoid(),
          bagNo: this.cashBagData.cashBagSerialNumber.trim(),
          remarks: this.cashBagData.remarks,
        });
        this.toggleModal(false);
      }
    },
  },
};
</script>
