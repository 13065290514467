<template>
  <section class="space-y-10">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <h3 class="subheader">
        Cash Bag Movement
      </h3>
      <br />
      <asom-card>
        <section class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field :label="'Location'" class="col-span-2">
            <p class="mt-3 text-sm text-gray-500">PSC</p>
          </asom-form-field>

          <div
            class="bg-white overflow-hidden sm:rounded-lg sm:shadow bg-gray-50"
          >
            <div class=" px-4 py-3 border-b border-gray-200">
              <div
                class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
              >
                <div class="ml-4 mt-2">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    In (Cash Bag S/N)
                  </h3>
                </div>
                <div class="ml-4 mt-2 flex-shrink-0">
                  <add-move-cash-bag
                    button-icon="math-plus"
                    @add-bag="addIncomingBag"
                  />
                </div>
              </div>
            </div>
            <ul class="divide-y divide-gray-200">
              <template v-for="bag in incomingCashBagList" :key="bag.tempId">
                <li>
                  <div class="px-4 py-3">
                    <div class="flex items-center justify-between">
                      <div class="text-sm font-medium text-gray-600 truncate">
                        S/N {{ bag.bagNo }}
                      </div>
                      <div class="ml-2 flex-shrink-0 flex">
                        <asom-button
                          variant="error"
                          size="sm"
                          outline
                          rounded="none"
                          icon="trash"
                          text=""
                          @click="removeBag(bag.tempId)"
                        >
                        </asom-button>
                      </div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>

          <div
            class="bg-white overflow-hidden sm:rounded-lg sm:shadow bg-gray-50"
          >
            <div class=" px-4 py-3 border-b border-gray-200">
              <div
                class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
              >
                <div class="ml-4 mt-2">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Out (Cash Bag S/N)
                  </h3>
                </div>
                <div class="ml-4 mt-2 flex-shrink-0">
                  <add-move-cash-bag
                    button-icon="math-minus"
                    @add-bag="addOutgoingBag"
                  />
                </div>
              </div>
            </div>
            <ul class="divide-y divide-gray-200">
              <template v-for="bag in outgoingCashBagList" :key="bag.tempId">
                <li>
                  <div class="px-4 py-3">
                    <div class="flex items-center justify-between">
                      <div class="text-sm font-medium text-gray-600 truncate">
                        S/N {{ bag.bagNo }}
                      </div>
                      <div class="ml-2 flex-shrink-0 flex">
                        <asom-button
                          variant="error"
                          size="sm"
                          outline
                          rounded="none"
                          icon="trash"
                          text=""
                          @click="removeBag(bag.tempId)"
                        >
                        </asom-button>
                      </div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
          <p
            v-if="inputStates('formData.cashBagList') === 'error'"
            class="text-sm text-red-700 col-span-2"
          >
            {{ "List of cash bag must not be empty." }}
          </p>
        </section>

        <template #footer>
          <div class="col-span-3 flex justify-between flex-1">
            <asom-button variant="link" text="Cancel" @click="$router.push({ name: 'Cash Float e-Log' })" />
            <asom-button text="Confirm" @click="onConfirmBtnClick" />
          </div>
        </template>
      </asom-card>
    </article>

    <asom-modal title="Confirm" v-model="showModal" :dismissible="false">
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div v-if="!checkAllBagsMovedOutAFC" class="pt-2">
        <p class="pb-2">There are Bags which not moved out of AFC</p>
        <asom-input-checkbox
          v-model="isForced"
          label="Force Move In"
          description="Force Bags to Move in to PSC"
        />
      </div>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button @click="cancelModal" text="Cancel" variant="secondary" />
        </div>
      </div>
    </asom-modal>
  </section>
</template>

<script>
import get from "lodash.get";
import filter from "lodash.filter";
import map from "lodash.map";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import {
  moveCertisBagsPSC,
  listBagStatus,
} from "../../../../services/cashManagement.service";
import AddMoveCashBag from "./AddMoveCashBag.vue";

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  components: {
    AddMoveCashBag,
  },
  data() {
    return {
      showModal: false,
      isSubmitting: false,
      isLoadingWitnessOptions: false,
      error: null,
      formData: {
        cashBagList: [],
      },
      isForced: false,
      checkAllBagsMovedOutAFC: true,
    };
  },
  validations() {
    let rules = {
      formData: {
        cashBagList: {
          required,
          minLength: minLength(1),
          $each: {
            bagNo: {
              required,
            },
          },
        },
      },
    };
    return rules;
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
      pscId: "cashManagement/stationCashAccounts/pscId",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
      userStationId: "selectedStation/id",
      lineId: "selectedStation/lineId",
    }),
    incomingCashBagList() {
      return this.formData.cashBagList.filter(({ movingIn }) => movingIn);
    },
    outgoingCashBagList() {
      return this.formData.cashBagList.filter(({ movingIn }) => !movingIn);
    },
  },
  methods: {
    cancelModal() {
      this.showModal = false;
      this.isForced = false;
    },
    addIncomingBag(bagData) {
      this.formData.cashBagList = [
        ...this.formData.cashBagList,
        {
          ...bagData,
          movingIn: true,
        },
      ];
    },
    addOutgoingBag(bagData) {
      this.formData.cashBagList = [
        ...this.formData.cashBagList,
        {
          ...bagData,
          movingIn: false,
        },
      ];
    },
    removeBag(bagTempId) {
      this.formData.cashBagList = this.formData.cashBagList.filter(
        ({ tempId }) => tempId !== bagTempId
      );
    },
    onConfirmBtnClick() {
      this.error = null;
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.formData.$invalid) {
        this.error = "Please complete all required fields";
        this.$scrollTop();
        return;
      }
      this.showModal = true;
      this.checkBagStatus();
    },
    async checkBagStatus() {
      this.isSubmitting = true;
      let inBagList = map(
        filter([...this.formData.cashBagList], { movingIn: true }),
        (bag) => bag.bagNo
      );
      const result = await listBagStatus({
        stationId: this.userStationId,
        lineId: this.lineId,
        bagNoList: inBagList,
      });
      if (result.success) {
        this.isSubmitting = false;
        let bags = get(result.payload, "bagStatusList", []);
        let detailedBags = [];
        inBagList.forEach((bagNo) => {
          bags.forEach((bag) => {
            if (bagNo == bag.bagNo) detailedBags.push(bag);
          });
        });
        if (this.isAllBagsMovedOut(detailedBags)) {
          this.checkAllBagsMovedOutAFC = true;
        } else {
          this.checkAllBagsMovedOutAFC = false;
        }
      } else this.isSubmitting = false;
    },
    isAllBagsMovedOut(bags) {
      if (filter(bags, { isMovedOut: true }).length == bags.length) return true;
      else return false;
    },
    async onSubmit() {
      this.error = null;
      this.isSubmitting = true;
      const result = await moveCertisBagsPSC({
        AFCId: this.afcId,
        PSCId: this.pscId,
        cashBagList: [...this.formData.cashBagList],
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
        isForced: this.isForced,
      });
      this.isSubmitting = false;
      if (!result.success) {
        this.error = result.payload;
        this.cancelModal();
        this.$scrollTop();
      } else {
        this.$router.push({ name: "Cash Float e-Log" });
      }
    },
  },
};
</script>
